<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-username"
    v-html="title"
  />
  <td
    v-else
    class="flex grid grid-cols-2 gap-2 overflow-x-hidden overflow-y-auto h-24"
  >
   <div v-bind:key="i"
    v-for="(applicant, i) in rowData.applicants">
      <div
        class="text-xs font-600 p-1 text-center border">{{getName(applicant)}}</div>
   </div>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  export default {
    name: 'UserField',
    mixins: [VuetableFieldMixin],
    methods: {
      getName(rowData) {
        const getName = this.rowField.switch?.getName;
        return getName ? getName(rowData) : `${rowData.firstName} ${rowData.lastName}`;
      },
    },
  };
</script>
<style scoped>
  .user-email {
    color: #848484;
  }
</style>