<template>
  <List
    title="applicants"
    resource="applicants"
    :basePath="basePath"
    :fields="fields"
    track-by="applicantionId"
    ref="list"
    on-row-click="edit"
    :search="true"
    search-placeholder="Search"
    :initial-filter="filter"
    :filter-mapper="mapFilterToQuery"
    :data="getData"
  >
    <template v-slot:filters="props">
      <div class="form-row">
        <div class="form-col">
          <SelectInput
            name="status"
            label="application status"
            :options="status"
          />
        </div>
        <div class="form-col">
          <DateRangeInput
            label="created date range"
            name="record_created"
            :max-date="today"
          />
        </div>
        <div class="form-col">
          <DateRangeInput
            label="app submitted date"
            name="application_submited"
            position="right"
            :max-date="today"
          />
        </div>
      </div>
      <div class="form-row justify-end">
        <button @click.stop="props.reset()" class="btn-transparent mr-8">Reset</button>
        <button class="btn-primary">Filter</button>
      </div>
    </template>
  </List>
</template>

<script>
  import {get} from 'lodash-es';
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import DateRangeFilterMixin from '@/components/auth/DateRangeFilterMixin';
  import List from '@/components/application_service/List.vue';
  import DateTimeField from '@/components/auth/list/fields/DateTimeField';
  import SelectInput from '@/components/form/SelectInput';
  import DateRangeInput from '@/components/form/DateRangeInput';
  import UserField from '@/components/application_service/UserField';
  import { mapGetters } from 'vuex';
  export default {
  name: 'Applicants',
  components: {
    SelectInput,
    List,
    DateRangeInput,
  },
  mixins: [ModalNavigation, NotifyMixin, ConfirmationMixin, DateRangeFilterMixin],
  data: function () {
    return {
      status: [
        {
          key: 'in progress',
          value: 'in progress'
        },
        {
          key: 'accepted',
          value: 'accepted'
        },
        {
          key: 'submitted',
          value: 'submitted'
        },
        {
          key: 'declined',
          value: 'declined'
        },
        {
          key: 'rental verification',
          value: 'rental verification'
        },
        {
          key: 'archived',
          value: 'archived'
        },
      ],
      filter: {
        record_created: {
          startDate: null,
          endDate: null,
        },
        application_submited: {
          startDate: null,
          endDate: null,
        },
      },
      fields: [
        {
          name: DateTimeField,
          title: 'record created',
          sortField: 'record_created',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'record_created',
            showTime: false,
          }
        },
        {
          title: 'application submited',
          name: DateTimeField,
          sortField: 'application_submited',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'application_submited',
            showTime: false,
          }
        },
        {
          name: 'application_status',
          sortField: 'application_status',
          title: 'status',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: DateTimeField,
          sortField: 'move_in',
          title: 'move in',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'move_in',
            showTime: false,
          }
        },
        {
          name: 'balance',
          sortField: 'balance',
          title: 'balance',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: UserField,
          sortField: 'coApplicants',
          title: 'applicants',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'coApplicants'
          }
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getApplicationsList: 'application_service/getApplicationsList'
    }),
    basePath: function () {
      return this.$route.path;
    },
    getData() {
      return this.getApplicationsList;
    }
  },
  methods: {
    mapFilterToQuery(values) {
      const { from: record_createdFrom, to: record_createdTo } = this.getFilterDateRange(values.record_created);
      const { from: application_submitedFrom, to: application_submitedTo } = this.getFilterDateRange(values.application_submited);
      const filter = {
        status: get(values, 'status'),
        application_submitedFrom,
        application_submitedTo,
        record_createdFrom,
        record_createdTo,
      };
      return filter;
    },
  },
};
</script>